<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuList" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
      navMenuList: { ...navMenuItems },
    }
  },
  computed: {
    ...mapState('StylistReviewStoreModule', ['reviewsPending']),
  },
  watch: {
    reviewsPending: {
      deep: true,
      handler(newReviewsPending) {
        if (newReviewsPending.totalItems !== false) {
          Object.values(navMenuItems).forEach((item, index) => {
            if (item.title === 'Reviews') {
              this.navMenuList[index] = { ...item, tag: newReviewsPending.totalItems }
            }
          })
        }
      },
    },
  },
  created() {
    this.fetchReviewsPending({ state: 0 })
  },
  methods: {
    ...mapActions('StylistReviewStoreModule', ['fetchReviewsPending']),
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
